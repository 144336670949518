body {



    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
  

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: lightgray;   /*  Tony This is where all app background color is set.  it was light grey*/
  
    /*  Tony 2 tone background */
    background-image: linear-gradient(to right, white , grey);

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
